import { FuseConfig } from '@fuse/types';

export const fuseConfig: FuseConfig = {
  	colorTheme: 'theme-default',
	customScrollbars: true,
	banner: 'https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-mn/banner.png',
	bgPage: '',
	bgMain: '',
	directorLogo: 'https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-mn/directorLogo.png',
	hqLogo: '',
	smallLogo: '',
    layout : {
        style    : 'vertical-layout-1',
        width    : 'fullwidth',
        navbar   : {
			primaryBackground: 'custom-primary-900',
            secondaryBackground: 'grey-100',
			customPrimaryBackground: '',
			customPrimaryForeground: '',
			customSecondaryBackground: '',
			customSecondaryForeground: '',
            folded             : false,
            hidden             : false,
            position           : 'left',
            variant            : 'vertical-style-2'
        },
		toolbar: {
			toolBarLogo: '',
            customBackgroundColor: true,
            background           : 'fuse-white-500',
			customToolbarBackground: '',
          	customToolbarForeground: '',
            hidden               : false,
            position             : 'below-static'
        },
        footer   : {
            background           : '',
			customFooterBackground: '',
			customFooterForeground: '',
            customBackgroundColor: true,
            hidden               : false,
			position: 'below-static',
			email: 'dan@mnsoftball.com',
			facebook: 'https://www.facebook.com/minnesotasoftball',
			instagram: 'https://www.instagram.com/mnsoftball/',
			twitter: 'https://twitter.com/usasoftballmn',
			youtube: 'https://www.youtube.com/@minnesotasoftball',
			linkedin: '',
			forum: ''
        },
        sidepanel: {
            hidden  : false,
            position: 'right'
        },
        pageheader: {
            primaryBackground: 'custom-warn-500',
			customHeaderPrimaryBackground: '',
			customHeaderPrimaryForeground: ''
        }, 
        linkActiveColor: {
            primaryBackground: 'custom-accent-500',
            customLinkBackground: '',
            customLinkForeground: ''
        }
  }
};
